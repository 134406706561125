<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="6" cols="12" class="py-0">
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-btn
                    depressed
                    small
                    class="mx-2"
                    color="deep-purple lighten-2"
                    @click="$router.go(-1)"
                    dark
                    mdi-keyboard-backspace
                    ><v-icon left> mdi-keyboard-backspace </v-icon> Back</v-btn
                  >
                </v-col>

                <v-col sm="6" cols="12" class="py-0">
                  <v-btn
                    class="mx-2 float-right white--text"
                    color="success"
                    @click.prevent="store()"
                    v-show="dataApprove !== 2"
                    :disabled="dataApprove == 1"
                    >Save & Approve</v-btn
                  >

                  <v-btn
                    class="mx-2 float-right"
                    depressed
                    :color="getColorStatus(approve)"
                    v-show="dataApprove == 1"
                    @click="validate()"
                    dark
                  >
                    <v-icon left> mdi-close-circle-outline </v-icon>Reject
                    Approve</v-btn
                  >
                  <v-btn
                    class="mx-2 float-right"
                    v-show="dataApprove == 2"
                    disabled
                  >
                    Already Get Session To Generate Transcript</v-btn
                  >
                  <download-excel
                    :data="listStudent"
                    :fields="exportFields"
                    name="session_scores.xls"
                  >
                    <v-btn
                      class="m-2 float-right"
                      color="primary"
                      v-show="dataApprove >= 1"
                      @click="filterStudent()"
                      ><h6>
                        Export
                        <i class="fa fa-file-excel fa-lg"></i>
                      </h6>
                    </v-btn>
                  </download-excel>
                </v-col>
              </v-row>
              <div style="margin-top: 20px"></div>

              <v-row justify="center">
                <v-col sm="12" cols="12">
                  <v-row>
                    <v-col sm="6" cols="12" md class="text-center">
                      <div class="text-h5">Result of the Examination</div>
                      <div class="text-h6">
                        {{ listSessionDetails.academic_year }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6" cols="12">
                      <div class="float-left text-h7">
                        Class: {{ listSessionDetails.major }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-card>
                    <div id="app">
                      <v-app>
                        <template>
                          <v-data-table
                            :headers="listHeader"
                            :items="listStudent"
                            class="elevation-1"
                            hide-default-header
                            hide-default-footer
                            :sort-by.sync="sortBy"
                            disable-pagination
                            :sort-desc.sync="sortDesc"
                          >
                            <template v-slot:header="{ props }">
                              <thead>
                                <tr>
                                  <th
                                    :colspan="listHeader.length + 1"
                                    style="text-align: center"
                                  >
                                    {{ listSessionDetails.major_gen }}
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    :colspan="listHeader.length + 1"
                                    style="text-align: center"
                                  >
                                    {{ listSessionDetails.session_name }}
                                  </th>
                                </tr>
                                <tr>
                                  <th class="text-center">No</th>
                                  <th
                                    :class="[
                                      'column sortable',
                                      sortDesc ? 'desc' : 'asc',
                                      header.value === sortBy ? 'active' : '',
                                    ]"
                                    v-for="header in props.headers"
                                    :key="header.text"
                                    class="text-center"
                                  >
                                    <span
                                      v-if="header.text == 'Student ID'"
                                      style="text-align: center"
                                    >
                                      <th rowspan="2">{{ header.text }}</th>
                                    </span>
                                    <span
                                      v-else-if="header.text == 'Student Name'"
                                      style="text-align: center"
                                      >{{ header.text }}</span
                                    >
                                    <span
                                      v-else-if="header.text == 'Total Score'"
                                      style="text-align: center"
                                      >{{ header.text }}
                                    </span>
                                    <span
                                      v-else-if="header.text == 'Rank'"
                                      style="text-align: center"
                                      >{{ header.text }}
                                    </span>
                                    <span v-else style="text-align: center"
                                      >{{ header.text }} (Credit:
                                      {{ header.credit }})</span
                                    >
                                    <v-icon
                                      small
                                      @click="changeSort(header.value)"
                                      v-if="sortDesc"
                                      color="red"
                                      >arrow_upward</v-icon
                                    >
                                    <v-icon
                                      small
                                      v-else
                                      color="red"
                                      @click="changeSort(header.value)"
                                      >arrow_downward</v-icon
                                    >
                                  </th>
                                </tr>
                              </thead>
                            </template>

                            <template v-slot:body="props">
                              <tr v-for="(index, i) in props.items" :key="i">
                                <td
                                  class="text-center font-weight-black"
                                  style="
                                    height: 35px;
                                    border: 1pt solid rgb(104, 102, 102) !important;
                                  "
                                >
                                  {{ 1 + i++ }}
                                </td>
                                <td
                                  v-for="(header, indx) in listHeader"
                                  :key="indx"
                                  style="
                                    height: 35px;
                                    border: 1pt solid rgb(104, 102, 102) !important;
                                  "
                                  class="text-center font-weight-black"
                                >
                                  <span>{{ index[header.value] }}</span>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </template>
                      </v-app>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  getSessionScoreBySessionId,
  storeSessionScore,
  validateSessionScore,
} from "@schoolbase/web-client-lib";
import { APPROVED, NOT_YET_APPROVED } from "@schoolbase/core";
import store from "@/store";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  name: "app",
  data() {
    return {
      id: "",
      sortBy: "",
      sortDesc: true,
      id_student: "",
      approveBool: "",
      isLoading: false,
      myLoading: false,
      dataApprove: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      listSessionScore: [],
      listHeader: [],
      listSessionDetails: [],
      approve: "",
      token: store.getters.getToken,
      listStudent: [],
      s: "",
      exportFields: {},
    };
  },
  components: {
    Loading,
  },
  computed: {
    isDisabled: function () {
      return !this.approveBool;
    },
  },
  methods: {
    filterStudent() {
      if (this.listStudent && this.listHeader) {
        for (let header of this.listHeader) {
          if (this.listStudent[0].hasOwnProperty(header.value)) {
            this.exportFields[header.text] = header.value;
          }
        }
      }
    },
    sort() {
      this.sortDesc = !this.sortDesc;
    },
    getColorStatus(approve) {
      if (approve === APPROVED) {
        return "red";
      } else if (approve === NOT_YET_APPROVED) {
        return "green";
      } else {
        return "blue";
      }
    },
    onReturn(item) {
      window.console.log(item);
    },

    async loadSessionScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getSessionScoreBySessionId(
          this.$route.params.sess_id,
          this.token
        );
        this.listHeader = APIResponse.payload.headers;
        this.listStudent = APIResponse.payload.students;

        this.listSessionScore = APIResponse.payload;
        this.listSessionDetails = APIResponse.payload.session_details;
        let data = APIResponse.payload.session_details.approval;
        this.dataApprove = data;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;
        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }

      if (this.dataApprove == 1) {
        return (this.approve = APPROVED), (this.approveBool = true);
      } else if (this.dataApprove == 0) {
        return (this.approve = NOT_YET_APPROVED);
      } else {
        return (this.approve = "Already Generate");
      }
    },

    changeSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.descending = false;
      }
    },

    async store() {
      try {
        const APIResponse = await storeSessionScore(this.$route.params.sess_id);
        if (APIResponse.message == "Store session Score successfully") {
          this.$swal({
            title: "Save Successfully",
            icon: "success",
            type: "success",
          }).then((okay) => {
            if (okay) {
              if (this.approve == NOT_YET_APPROVED) {
                this.$swal({
                  title: "Do you wanna approve this score session ?",
                  type: "question",
                  confirmButtonText: "Approve Now",
                  showCancelButton: true,
                  cancelButtonText: "Not Now",
                  denyButtonText: true,
                  confirmButtonColor: "#4caf50",
                }).then((isValidate) => {
                  if (isValidate.isConfirmed) {
                    validateSessionScore(this.$route.params.sess_id);
                    this.$swal({
                      title: "Session Score Was Approved",
                      icon: "success",
                      showCancelButton: false,

                      type: "success",
                    }).then((result) => {
                      if (result) {
                        window.location.reload();
                      }
                    });
                  } else {
                    return false;
                  }
                });
              } else {
                return false;
              }
            }
          });
        }
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    async validate() {
      try {
        await validateSessionScore(this.$route.params.sess_id);
        this.loadSessionScore();
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  async mounted() {
    this.loadSessionScore();
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Krasar-Bold", serif;
  font-size: 16px !important;
  color: black !important;
  border: 1pt solid rgb(104, 102, 102) !important;
}
</style>
